<!--
 * @Autor: syq
 * @Date: 2021-07-15 09:34:22
-->
<template>
  <div id="online">
    <div class="kefu">
      <div class="people">
        <img
          class="img1"
          src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic.51yuansu.com%2Fpic3%2Fcover%2F01%2F87%2F09%2F5981a2e58cd16_610.jpg&refer=http%3A%2F%2Fpic.51yuansu.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1628489418&t=14a87f5c596dff6f3098cbd817472ad5"
          alt=""
        />
        <span class="text">立白平台客服</span>
        <span class="time">2020-10-22 8:45:21 </span>
        <div class="footer">
          你好，这个问题我已经和仓库汇报了，请您耐心等待…
        </div>
      </div>
      <div>
        <p></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
#online {
  width: 100%;
  height: 100%;
  .kefu {
    width: 100%;
    padding: 0px 10px 10px;
    height: 82px;
    line-height: 18px;
    background-color: aliceblue;
    margin-top: 15px;
    border: 1px solid rgb(170, 170, 170);
    position: relative;
    img {
      //   border-radius: 50%;
      width: 50px;
      margin-left: -8px;
    }
    .people {
      // display: flex;
      // justify-content: left;
      // align-items: center;
      // padding-left: 25px;
      .img1 {
        position: absolute;
        top: 10px;
        left: 30px;
      }
      .text {
        position: absolute;
        top: 16px;
        left: 85px;
        font-size: 13px;
        color: #333333;
        font-weight: 400;
      }
      .time {
        font-size: 11px;
        color: rgb(127, 127, 127);
        position: absolute;
        right: 7px;
        top: 13px;
      }
    }
    .footer {
      font-size: 11px;
      color: rgb(127, 127, 127);
      font-weight: 400;
      position: absolute;
      top: 45px;
      left: 87px;
    }
  }
}
</style>
