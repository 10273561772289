<!--
 * @Description: 
 * @Author: rodchen
 * @Date: 2021-07-19 15:06:21
 * @LastEditTime: 2021-08-19 16:50:43
 * @LastEditors: Please set LastEditors
-->
<template>
  <div id="message-card">
    <van-list
      :loading="loading"
      :finished="infoData.finished"
      finished-text="没有更多了"
      @load="getMessageList"
    >
      <van-swipe-cell v-for="(item, index) in infoData.list" :key="index">
         <div class="card-content">
             <div class="card-left-icon">
                <img :src="xttzIcon" alt="">
             </div>
             <div class="card-right-text">
                <div class="card-right-text-top">
                  <div class="emailTitle">{{ item.emailTitle }}</div>
                  <div class="time">{{ handleCommonTimeRender(item.createTime) }}</div>
                </div>
                <div class="card-rght-text-bottom">
                    <div class="content">{{`${item.content}`}}</div>
                </div>
             </div>
         </div>
        <template #right>
          <van-button square text="删除" type="danger" class="delete-button" />
        </template>
      </van-swipe-cell>
    </van-list>
  </div>
</template>

<script>
import xttzIcon from "../../../assets/icon/xiaoxi_xitongtongzi_icon.svg";
import { handleCommonTimeRender } from "../../../utils/utils.js";

export default {
  props:{infoData:Object,loading:Boolean,queryInformsData:Function},
  data() {
    return {
      xttzIcon,
      handleCommonTimeRender,
      // list: [],
      // loading: false,
      // finished: false,
      // currentPage: 1,
    };
  },
  mounted() {
    // this.getMessageList();
  },
  methods: {
    getMessageList(){
      this.$emit('queryInformsData')
    }
    // getMessageList() {
    //   this.loading = true;
    //   message(++this.currentPage).then((res) => {
    //     this.loading = false;
    //     if (res.status == 0) {
    //       this.list = this.list.concat(res.data.items);
    //       this.currentPage = res.data.page;
    //       if (res.data.items.length < 10) {
    //         this.finished = true;
    //       }
    //     }
    //   });
    // },
  },
};
</script>

<style lang="less" scoped>
#message-card {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #fff;
  .van-list {
    .card-content {
      display: flex;
      width: 100%;
      height: 80px;
      padding: 19px 0 19px 24px;
      .card-left-icon{
        width: 42px;
        img{
          width: 42px;
          height: 42px;
        }
      }
      .card-right-text{
        flex: 1;
        padding:0 6px 0 16px;
        .card-right-text-top {
          display: flex;
          align-items: center;
          justify-content:space-between;
          width: 100%;
          .emailTitle {
            flex: 1;
            height: 21px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-size: 16px;
            line-height: 21px;
            font-weight: 500;
            color:#000;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space:normal;
          }
          .time{
            width: 120px;
            font-family: Montserrat, Montserrat-Regular;
            font-size: 12px;
            font-weight: 400;
            color: #1c2529;
            opacity: 0.36;
          }
        }
        .card-rght-text-bottom {
          width: 100%;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          font-family: SFProText, SFProText-Regular;
          font-weight: 400;
          color: #666;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space:normal;
        }
      }
    }
  }
}
</style>
