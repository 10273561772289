import { render, staticRenderFns } from "./Informs.vue?vue&type=template&id=73245923&scoped=true&"
import script from "./Informs.vue?vue&type=script&lang=js&"
export * from "./Informs.vue?vue&type=script&lang=js&"
import style0 from "./Informs.vue?vue&type=style&index=0&id=73245923&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73245923",
  null
  
)

export default component.exports