/*
 * @Autor: syq
 * @Date: 2021-07-12 16:37:32
 */

import request from "../../request/request";

// 获取前端类目树父节点集合
//系统客服
const message = async (currentPage,status) =>
  request({
    url: `/message/getMsgPager?currentPage=${currentPage || 1}&qp-status-in=${status}`,
    method: "get",
  });
//查询全部消息
const allmessage = async (currentPage) =>
  request({
    url: `/message/getMsgPager?currentPage=${currentPage || 1}&qp-status-in=1,3`,
    method: "get",
  });
//状态（已读，未读）
const readMsg = async (ids) =>
  request({
    url: `/message/readMsg`,
    method: "patch",
    data:ids,
    headers: {
      'Content-Type': 'application/json'
    }
  });

export { message, readMsg ,allmessage};
