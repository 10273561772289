<!--
 * @Autor: syq
 * @Date: 2021-07-11 10:40:58
-->
<template>
  <div id="message">
    <van-tabs @click="initInfomsData(1,activeName)" v-model="activeName" animated sticky>
      <van-tab title="全部" name="informs">
        <div class="informs"  >
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="queryInformsData"
              :offset="50"
            >
              <van-swipe-cell v-for="(item, index) in infoData" :key="index">
                <div class="card-content"  @click="msgdetail(item)">
                  <div class="card-left-icon">
                    <img :src="xttzIcon" alt="" />
                  </div>
                  <div class="card-right-text">
                    <div class="card-right-text-top">
                      <div class="emailTitle">{{ item.emailTitle }}</div>
                      <div class="time">
                        {{ handleCommonTimeRender(item.createTime) }}
                      </div>
                    </div>
                    <div class="card-rght-text-bottom">
                      {{item.content}}
                      <!-- <div class="content">{{ `${item.content}` }}</div> -->
                    </div>
                  </div>
                </div>
                <!-- <template #right>
                  <van-button block text="删除" type="danger" class="delete-button" />
                </template> -->
              </van-swipe-cell>
            </van-list>
        </div>
      </van-tab>
      <van-tab title="未读"  :badge="haveweidu" name="1">
        <div class="informs">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="queryInformsData"
              :offset="50"
            >
              <van-swipe-cell v-for="(item, index) in infoData" :key="index">
                <div class="card-content"  @click="msgdetail(item)">
                  <div class="card-left-icon">
                    <img :src="xttzIcon" alt="" />
                  </div>
                  <div class="card-right-text">
                    <div class="card-right-text-top">
                      <div class="emailTitle">{{ item.emailTitle }}</div>
                      <div class="time">
                        {{ handleCommonTimeRender(item.createTime) }}
                      </div>
                    </div>
                    <div class="card-rght-text-bottom">
                      {{item.content}}
                      <!-- <div class="content">{{ `${item.content}` }}</div> -->
                    </div>
                  </div>
                </div>
                <!-- <template #right>
                  <van-button block text="删除" type="danger" class="delete-button" />
                </template> -->
              </van-swipe-cell>
            </van-list>
        </div>
      </van-tab>
      <van-tab title="已读" name="3">
        <div class="informs">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="queryInformsData"
              :offset="50"
            >
              <van-swipe-cell v-for="(item, index) in infoData" :key="index">
                <div class="card-content"  @click="msgdetail(item)">
                  <div class="card-left-icon">
                    <img :src="xttzIcon" alt="" />
                  </div>
                  <div class="card-right-text">
                    <div class="card-right-text-top">
                      <div class="emailTitle">{{ item.emailTitle }}</div>
                      <div class="time">
                        {{ handleCommonTimeRender(item.createTime) }}
                      </div>
                    </div>
                    <div class="card-rght-text-bottom">
                      {{item.content}}
                      <!-- <div class="content">{{ `${item.content}` }}</div> -->
                    </div>
                  </div>
                </div>
                <!-- <template #right>
                  <van-button block text="删除" type="danger" class="delete-button" />
                </template> -->
              </van-swipe-cell>
            </van-list>
        </div>
      </van-tab>
      </van-tabs>
     
      <!-- <van-tabs v-model="activeName" sticky @change="tabsChange">
      <van-tab title="客服列表" name="newsService">
        <news-service v-if="activeName === 'newsService'" />
      </van-tab>
      <van-tab title="系统通知" name="informs">
        <div class="informs" v-if="activeName === 'informs'">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="queryInformsData"
            :offset="50"
            :immediate-check="false"
          >
            <van-swipe-cell v-for="(item, index) in infoData" :key="index">
              <div class="card-content">
                <div class="card-left-icon">
                  <img :src="xttzIcon" alt="" />
                </div>
                <div class="card-right-text">
                  <div class="card-right-text-top">
                    <div class="emailTitle">{{ item.emailTitle }}</div>
                    <div class="time">
                      {{ handleCommonTimeRender(item.createTime) }}
                    </div>
                  </div>
                  <div class="card-rght-text-bottom">
                    <div class="content">{{ `${item.content}` }}</div>
                  </div>
                </div>
              </div>
              <template #right>
                <van-button
                  block
                  text="删除"
                  type="danger"
                  class="delete-button"
                />
              </template>
            </van-swipe-cell>
          </van-list>
        </div>
      </van-tab>
    </van-tabs> -->
  </div>
</template>

<script>
import xttzIcon from "../../assets/icon/xiaoxi_xitongtongzi_icon.svg";
import { handleCommonTimeRender } from "../../utils/utils.js";
import NewsService from "./components/NewsService.vue";
import Informs from "./components/Informs.vue";
import { message,allmessage,readMsg } from "./service";
import { Toast } from "vant";
import { Dialog } from 'vant';
export default {
  components: { Informs, NewsService },
  data() {
    return {
      xttzIcon,
      handleCommonTimeRender,
      activeName: "1",
      infoData: [],
      currentPage: 1,
      finished: false,
      loading: false,
      haveweidu:''
    };
  },
  methods: {
    //消息详情
    async msgdetail(item){
      console.log(item.id);
      const res = await readMsg(item.id)
      Dialog.alert({
        title: item.emailTitle,
        message: item.content,
        theme: 'round-button',
      }).then(() => {
        console.log(item.id)
       this.initInfomsData(1,this.activeName)
      });
    },
    // 初始化调用系统通知
    async initInfomsData(current,activeName) {
      //判断查询哪个消息类型
      if(activeName == "informs"){
          const res = await allmessage(current);
          if (res.status === "0") {
            console.log(res.data,"message");
            if (res.data.items.length < 10) {
              this.finished = true;
            }
            this.currentPage = Number(res.data.page);
            this.infoData = res.data.items;
            this.loading = false;
            //再次调接口查有没有未读消息
            const res1 = await message(current,"1");
              if (res.status === "0") {
                if(res.data.items.length>0){
                  if(res.data.total > 99){
                    this.haveweidu = '99+'
                  }else{
                    this.haveweidu =res.data.total
                  }
                }
              }
          } else {
            this.loading = false;
            this.finished = true;
            Toast.fail(res.msg); 
          }  
      }else{
        const res = await message(current,activeName);
        //判断是否有未读消息
        if(activeName == "1"){
          if (res.status === "0") {
            if(res.data.items.length>0){
              if(res.data.total > 99){
                this.haveweidu = '99+'
              }else{
                this.haveweidu =res.data.total
              }
            }
          }
        }
        if (res.status === "0") {
          if (res.data.items.length < 10) {
            this.finished = true;
          }
          this.currentPage = Number(res.data.page);
          this.infoData = res.data.items;
          this.loading = false;
        } else {
          this.loading = false;
          this.finished = true;
          Toast.fail(res.msg);
        }
      }
     
    },
    // 再查询系统通知
    async queryInformsData() {
      this.loading = true;
      if (!this.finished) {
        this.currentPage = this.currentPage + 1;
      }
      if(this.activeName == "informs"){
        const res = await allmessage(this.currentPage);
        if (res.status === "0") {
          if (res.data.items.length < 10) {
            this.finished = true;
          }
          this.currentPage = Number(res.data.page);
          this.infoData = this.infoData.concat(res.data.items);
          this.loading = false;
        } else {
          this.loading = false;
          this.finished = true;
          Toast.fail(res.msg);
        }
      }else{
        const res = await message(this.currentPage,this.activeName);
        if (res.status === "0") {
          if (res.data.items.length < 10) {
            this.finished = true;
          }
          this.currentPage = Number(res.data.page);
          this.infoData = this.infoData.concat(res.data.items);
          this.loading = false;
        } else {
          this.loading = false;
          this.finished = true;
          Toast.fail(res.msg);
        }
      }
     
    },
  },
  mounted() {
      this.initInfomsData(1,this.activeName);
  },
};
</script>

<style lang="less" scoped>
#message {
  width: 100%;
  height: 100%;
  

  /deep/ .informs {
    width: 100%;
    height: 100%;
    background-color: #fff;
    .van-list {
      width: 100%;
      height: 100%;
      .card-content {
        display: flex;
        width: 100%;
        height: 80px;
        // padding: 19px 0 19px 24px;
        .card-left-icon {
          padding: 18px 0 18px 24px;
          width: 66px;
          height: 78px;
          img {
            width: 42px;
            height: 42px;
          }
        }
        .card-right-text {
          flex: 1;
          padding: 18px 6px 18px 8px;
          //border-bottom: 1px solid #E9E9E9;
          .card-right-text-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .emailTitle {
              flex: 1;
              height: 21px;
              font-family: PingFangSC, PingFangSC-Medium;
              font-size: 16px;
              line-height: 21px;
              font-weight: 500;
              color: #000;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .time {
              width: auto;
              font-family: Montserrat, Montserrat-Regular;
              font-size: 12px;
              font-weight: 400;
              color: #1c2529;
              opacity: 0.36;
            }
          }
          .card-rght-text-bottom {
            width: 282px;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
            font-family: SFProText, SFProText-Regular;
            font-weight: 400;
            color: #666;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .van-button--block {
      height: 100%;
    }
  }
}
.van-swipe-cell{
  border-bottom: 1px solid #E9E9E9;
}
</style>
